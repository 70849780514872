








import { Component, Vue } from 'vue-property-decorator';


@Component({
  props: {
    title: String,
    found: Number,
    total: Number,
  },
})
export default class TooltipBase extends Vue {
  get args() {
    return this.$props;
  }

  public getPercent(found: number, total: number) {
    if (total > 0) return Math.floor(found / total * 100);
    return 0;
  }
}
