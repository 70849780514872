function timeFormatted(ms: number, params: string = '', onlyTotals: boolean = false) {

    interface DeltaType {
        ttl?: number;
        ms?: number;
    }

    const delta: DeltaType = {
        ttl: undefined,
        ms: undefined,
    };

    const measurements = {
            w: 604800000,
            d: 86400000,
            h: 3600000,
            m: 60000,
            s: 1000,
            };

    const flags = params || 'wdhms';

    delta.ttl = delta.ms = ms;

    // do caclulations for each flag
    for (const item of flags) {
        delta[item] = delta.ms / measurements[item];
        // unless totals are specified,
        // value is broken down into components
        if (!onlyTotals) {
            delta[item] = Math.floor(delta[item]);
            delta.ms -= delta[item] * measurements[item];
        }
    }
    return delta;
}

export default function timeToString(ms: number) {
    const res = timeFormatted(ms);
    const resArr: string[] = [];

    const names = {
        w: 'н',
        d: 'д',
        h: 'ч',
        m: 'мин',
    };

    if (res.ttl && res.ttl < 60000) return 'меньше минуты';

    for (const name in names)
        if (res[name] !== Infinity && res[name] > 0) resArr.push(`${res[name]}${names[name]}`);

    return resArr.slice(0, 3).join(':');
}

export const estimateTimeToGo = (
    total: number, started: number, current: number, startedTime: Date,
    ) => {
    if (startedTime === undefined || started === undefined) return '???';

    const curTime = new Date();
    const timeDelta =  curTime.getTime() - startedTime.getTime();
    const itemsDone = current - started;
    const itemsToGo = total - current;
    const speed = itemsDone / timeDelta;
    const timeToGoMS = itemsToGo / speed;

    return timeToString(timeToGoMS) || '???';
  };
