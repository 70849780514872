












































































































































































import { Component, Vue } from 'vue-property-decorator';

import {
  dispatchDeleteAvitoTask,
  dispatchGetAvitoTasks,
  dispatchKillAvitoTask,
  dispatchRunAvitoTask,
  dispatchStatAvitoTasks,
  dispatchDownloadAvitoTaskResult,
  dispatchDownloadAvitoTaskFile,
} from '@/store/avito-tasks/actions';
import { readAvitoTasks } from '@/store/avito-tasks/getters';
import { AVITO_TASK_UPDATE_RATE } from '../../../constants';
import { estimateTimeToGo } from '../utils';
import { IAvitoTaskStats } from '@/interfaces/avito-tasks';
import ProgressBar from '@/components/ProgressBar.vue';
import TooltipBase from '@/components/TooltipBase.vue';

@Component({
  components: {
    ProgressBar,
    TooltipBase,
  },
})
export default class AvitoTasks extends Vue {
  public headers = [
    {
      text: 'ID',
      sortable: true,
      value: 'id',
      align: 'left',
    },
    {
      text: 'Имя',
      sortable: true,
      value: 'name',
      align: 'left',
    },
    {
      text: 'Описание',
      sortable: true,
      value: 'description',
      align: 'left',
    },
    {
      text: 'Менеджер',
      sortable: true,
      value: 'manager',
      align: 'left',
    },
    {
      text: 'Глубина',
      sortable: true,
      value: 'deep',
      align: 'left',
    },
    {
      text: 'Создано',
      sortable: true,
      value: 'date_create',
      align: 'left',
    },
    {
      text: 'Завершено',
      sortable: true,
      value: 'date_end',
      align: 'left',
    },
    {
      text: 'Статус',
      sortable: true,
      value: 'status',
      align: 'left',
    },
    {
      text: 'Действия',
      value: 'id',
    },
  ];

  public updateTimer?: number = undefined;

  get tasks() {
    return readAvitoTasks(this.$store);
  }

  public tableLoaded = false;

  public async mounted() {
    await dispatchGetAvitoTasks(this.$store);
    await dispatchStatAvitoTasks(this.$store);
    this.tableLoaded = true;

    this.updateTimer = setInterval(async () => {
      await dispatchGetAvitoTasks(this.$store);
      await dispatchStatAvitoTasks(this.$store);
      }, AVITO_TASK_UPDATE_RATE,
    );
  }

  public destroyed() {
    clearInterval(this.updateTimer);
    this.updateTimer = undefined;
  }

  public async deleteTask(taskId: number) {
    await dispatchDeleteAvitoTask(this.$store, {id: taskId});
    await dispatchGetAvitoTasks(this.$store);
  }

  public async runTask(taskId: number) {
    await dispatchRunAvitoTask(this.$store, {id: taskId});
  }

  public async killTask(taskId: number) {
    await dispatchKillAvitoTask(this.$store, {id: taskId});
  }

  public async downloadAvitoTaskResult(taskId: number, result: string) {
    await dispatchDownloadAvitoTaskResult(this.$store, { id: taskId, result });
  }

  public async downloadAvitoTaskFile(taskFile: string) {
    await dispatchDownloadAvitoTaskFile(this.$store, { taskFile });
  }

  public showEstimateTimeToGo(total: number, started: number, current: number, startedTime: Date) {
    if (current >= total || started === undefined || startedTime === undefined) return '';
    return `Еще ${estimateTimeToGo(total, started, current, startedTime)}`;
  }

  public getPercent(statData: IAvitoTaskStats, divider = 'found_count', divisible = 'total_count') {
    if (!statData[divisible]) return 0;
    return Math.floor(statData[divider] / statData[divisible] * 100);
  }

}
