















import { Component, Vue } from 'vue-property-decorator';


@Component({
  props: {
    color: String,
    value: Number,
    indeterminate: Boolean,
  },
})
export default class ProgressBar extends Vue {
  get args() {
    return this.$props;
  }
}
